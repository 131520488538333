.examItem {
  width: 100%;
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  font-weight: 700;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  margin-bottom: 12px;
  text-align: center;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0 16px;
  box-sizing: border-box;

  &:hover {
    background: #e6f7ff;
  }

  &.is_active {
    background: #cfedfc;
  }
}

.examItemWrap {
  height: 440px;
}
