.ruleItem {
  border: 1px solid #f0f0f0;
  border-radius: 6px;
  margin-top: 12px;
  overflow: hidden;

  &Header {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f0f0f0;
    padding: 4px 8px;
    box-sizing: border-box;
  }

  &Body {
    background: rgba(245, 245, 245, 0.3);
    min-height: 30px;
    padding: 8px 16px;
  }

  &ChildRule {
    min-height: 50px;
    margin-top: 8px;
    padding: 8px 16px;
    border: 1px solid #f0f0f0;
  }
}
