.text-center {
  text-align: center;
}

.text-danger {
  color: #f5222d;
}

.text-primary {
  color: #1677ff;
}

.text-success {
  color: #52c41a;
}

.text-warning {
  color: #faad14;
}

.text-overflow-1 {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
