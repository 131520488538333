.monitorTitle {
  background: rgb(240, 240, 240);
  padding: 6px 12px;
  font-weight: bold;
}

.monitorLabel {
  width: 120px;
  text-align: right;
  margin-right: 32px;
  color: #666;
}

.monitorItem {
  margin-right: 16px;
  padding: 12px 0;
  display: flex;
  align-items: center;

  &:nth-child(2n) {
    background: rgb(250, 250, 250);
  }
}
