$--space-list: (0:0 , 1:4px, 2:8px, 3:16px, 4:32px, 5:64px);

@each $i, $v in $--space-list {
  .p-#{$i} {
    padding: $v;
  }

  .pt-#{$i} {
    padding-top: $v;
  }

  .pr-#{$i} {
    padding-right: $v;
  }

  .pb-#{$i} {
    padding-bottom: $v;
  }

  .pl-#{$i} {
    padding-left: $v;
  }

  .px-#{$i} {
    padding-left: $v;
    padding-right: $v;
  }

  .py-#{$i} {
    padding-top: $v;
    padding-bottom: $v;
  }

  .m-#{$i} {
    margin: $v;
  }

  .mt-#{$i} {
    margin-top: $v;
  }

  .mr-#{$i} {
    margin-right: $v;
  }

  .mb-#{$i} {
    margin-bottom: $v;
  }

  .ml-#{$i} {
    margin-left: $v;
  }

  .mx-#{$i} {
    margin-left: $v;
    margin-right: $v;
  }

  .my-#{$i} {
    margin-top: $v;
    margin-bottom: $v;
  }
}
