$--font-weight-list: (100, 200, 300, 400, 500, 600, 700, 800, 900);

.font-weight {
  font-weight: bold;
}

@each $v in $--font-weight-list {
  .font-weight-#{$v} {
    font-weight: $v;
  }
}
