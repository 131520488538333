.editorContent {
  margin-top: 16px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}

.editorWrap {
  flex-grow: 1;
}

.editorControl {
  width: 220px;
  height: 600px;
  border: 1px solid #d1d1d1;
  border-left: none;
  box-sizing: border-box;
}

.controlTitle {
  height: 40px;
  line-height: 40px;
  background: #f8f8f8;
  padding: 0 8px;
  border-bottom: 1px solid #f5f5f5;
}

.controlSearchBar {
  height: 32px;
  padding: 3px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.controlBody {
  height: calc(100% - 79px);
  padding: 8px 0;
  box-sizing: border-box;
  overflow-y: scroll;
}

.fieldItem {
  border: 1px solid transparent;
  border-bottom-color: #f5f5f5;
  padding: 3px 18px 3px 8px;
  cursor: pointer;
  position: relative;

  &:hover {
    background: #e6f7ff;
    color: #1890ff;
    border-color: #1890ff;
  }

  .isSelected {
    width: 16px;
    position: absolute;
    right: 2px;
    top: 50%;
    transform: translate(0, -50%);
  }
}
