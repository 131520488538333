.examItem {
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  font-weight: 700;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  margin-bottom: 12px;
  text-align: center;
  cursor: pointer;

  &:hover {
    background: #e6f7ff;
  }
}
