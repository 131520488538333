.formTitle {
  color: #222;
  border-bottom: 1px solid #d9d9d9;
  margin: 8px 0;
  padding: 4px 0 4px 10px;
  position: relative;

  &::before {
    content: '';
    width: 3px;
    height: 14px;
    background: #1890ff;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
  }
}
