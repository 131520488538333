$--align-items-list: (start: flex-start, center: center, end: flex-end, stretch: stretch, baseline: baseline);
$--justify-content-list: (start: flex-start, center: center, between: space-between, evenly: space-evenly, around: space-around, end: flex-end);

.d-flex {
  display: flex;
}

@each $h, $v in $--align-items-list {
  .align-items-#{$v} {
    align-items: $v;
  }
}

@each $h, $v in $--justify-content-list {
  .justify-content-#{$h} {
    justify-content: $v;
  }
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.flex-column {
  flex-direction: column;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

