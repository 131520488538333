.batchItem {
  border: 1px solid rgb(239, 239, 239);
  margin: 16px 0;

  &Header {
    background: rgb(248, 247, 250);
    padding: 6px 12px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    user-select: none;
    border-bottom: 1px solid transparent;
  }

  &Body {
    transition: all 0.3s ease;
    height: 0;
    overflow: scroll;
    user-select: none;
    padding: 0 16px;
  }

  &.isCollapseActive &Header {
    border-bottom-color: rgb(239, 239, 239);
  }

  &.isCollapseActive &Body {
    padding: 16px;
    height: auto;
  }
}
