.infoWrap {
  height: 560px;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  flex-grow: 1;
}

.infoTitle {
  background: rgb(240, 240, 240);
  padding: 6px 12px;
  font-weight: bold;
}

.infoItem {
  padding: 12px 0;
  display: flex;
  align-items: center;

  &:nth-child(2n) {
    background: rgb(251, 251, 251);
  }
}

.infoLabel {
  width: 100px;
  min-width: 100px;
  text-align: right;
  margin-right: 32px;
  color: #666;
}

.infoValue {
  flex-grow: 1;
  color: #666;
  padding: 0 16px 0 0;
}

.infoValueTable {
  :global {
    th.ant-table-cell {
      white-space: nowrap;
    }

    .ant-table tbody .ant-table-cell {
      padding: 4px 8px!important;
    }
  }
}

.userPhoto {
  width: 120px;
  height: 170px;
  position: absolute;
  right: 0;
  top: 34px;
  border: 1px solid #f5f5f5;
  background: #fdfdfd;
}

.operatingRecordWrap {
  width: 280px;
  position: relative;
  flex-grow: 0;

  .operatingRecordTitle {
    background: rgb(240, 240, 240);
    padding: 6px 12px;
    font-weight: bold;
  }

  .operatingRecord {
    min-width: 200px;
    max-width: 200px;
    height: 526px;
    padding-left: 16px;
    overflow-y: scroll;
  }
}

.sampleTable {
  width: 880px;
  margin: 0 auto;
  p {
    margin: 0;
  }
}

.blank {
  height: 45px;
  background-color: rgb(251, 251, 251);
}


