.pageTitle {
  font-weight: bold;
  border-bottom: 1px dashed #aaa;
  padding: 6px 0;
}

.batchTabBar {
  position: relative;
  height: 30px;
  margin-top: 16px;
  border-bottom: 1px solid #69c0ff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.batchTabItem {
  position: relative;
  height: 28px;
  line-height: 28px;
  margin-right: 16px;
  padding: 0 10px;
  font-weight: bold;

  &.isActive {
    color: #1677ff;
    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 3px;
      background: #1677ff;
      bottom: -1px;
      left: 0;
    }
  }
}

.dataDashboard {
  border-top: 1px dashed #aaa;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 32px 0;

  &Item {
    height: 100px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 16px;
  }

  &Item:not(:first-child) {
    border-left: 2px solid #cccc;
  }

  &ItemNumber {
    font-size: 32px;
    margin-bottom: 8px;
  }
}
