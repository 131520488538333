.app-logo {
    font-size: 20px;
    color: #ffffff;
    padding: 10px 24px;
}

.logo-text {
    color: #ffffff;
}

.app-side-menu {
    max-height: calc(100vh - 80px);
    overflow-y: scroll;
}


.app-side-menu>li:first-child.ant-menu-item-selected {
    background-color: transparent!important;
}

.ant-menu-light .ant-menu-item-selected, .ant-menu-light>.ant-menu .ant-menu-item-selected {
    background: #bae0ff;
}

.ant-menu-light.ant-menu-root.ant-menu-inline, .ant-menu-light>.ant-menu.ant-menu-root.ant-menu-inline, .ant-menu-light.ant-menu-root.ant-menu-vertical, .ant-menu-light>.ant-menu.ant-menu-root.ant-menu-vertical {
    border-inline-end: 0
}

.ant-layout-sider {
    border-inline-end: 1px solid rgba(5, 5, 5, 0.06)
}
