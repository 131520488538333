.app_loading {
  width: 100%;
  background: rgba(255, 255, 255, 0.7);
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  box-sizing: border-box;

  &_wrap {
    //box-sizing: border-box;
  }

  .tip {
    font-size: 16px;
    color: #222;
  }

  &.no_fixed {
    position: relative;
  }

  :global {
    .adm-spin-loading {
      --size: 70px;
    }
  }
}
