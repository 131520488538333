.userInfoWrap {
  padding: 20px 40px;
  background: url("../../assets/images/login-bg.jpg") no-repeat;
  background-size: 100%;
  background-position: center;

  .label {
    width: 80px;
    color: #595959;
    text-align: right;
  }
}

.userLogo {
  width: 100px;
  height: 100px;
  border: 1px solid #8c8c8c;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboardTitle {
  position: relative;
  border-bottom: 1px solid rgba(232, 235, 244, 0.81);
  padding: 6px 16px;

  &::before {
    content: '';
    display: block;
    width: 3px;
    height: 18px;
    position: absolute;
    left: 2px;
    top: 50%;
    transform: translate(0, -50%);
    background: #1677ff;
  }
}
