body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.w-50 {
  width: 50%;
}

.w-75 {
  width: 75%;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.tox-tinymce {
  border: 1px solid #d1d1d1 !important;
  border-radius: 0 !important;
}

.tox-statusbar__branding {
  display: none;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
  z-index: 9999;
  display: flex;
  align-items: center;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

::-webkit-scrollbar {
  width: 5px;

  &:hover {
    width: 8px;
  }
}

::-webkit-scrollbar-track {
  //-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05);
  //background-color: rgba(238, 247, 250, 0.53);
}

::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05);
  background: rgba(201, 201, 203);
  border-radius: 2px;

  &:hover {
    background: rgb(186, 186, 187);
  }
}

.form-title {
  color: #222;
  border-bottom: 1px solid #d9d9d9;
  margin: 8px 0;
  padding: 4px 0 4px 10px;
  position: relative;

  &::before {
    content: '';
    width: 3px;
    height: 14px;
    background: #1890ff;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
  }
}

.ant-table tbody .ant-table-cell {
  padding: 8px 16px!important;
}

.ant-table-wrapper {
  padding: 8px 0;
}
