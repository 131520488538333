.loginPage {
  width: 100vw;
  min-width: 1200px;
  height: 100vh;
  background: url("../../assets/images/login-bg.jpg") no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logonWrap {
  display: flex;
  justify-content: center;
}

.loginTitle {
  width: 470px;
  font-size: 30px;
  color: #001C4B;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginSubTitle {
  font-size: 30px;
  color: #001C4B;
  text-align: center;
  font-weight: 600;
}

.loginContainer {
  position: relative;
  width: 420px;
  background: #fff;
  padding: 52px 46px 57px;
  border-radius: 12px;
  box-shadow: 0 4px 19px 0 rgba(0, 0, 0, 0.1);
}

.loginTip {
  position: absolute;
  bottom: -30px;
  color: #e4393c;
  font-size: 12px;
}

.loginButton {
  width: 220px;
}
