.processCard {
  margin-bottom: 16px;
  border: 1px solid rgb(235, 235, 235);

  &Heard {
    min-height: 40px;
    padding: 8px 16px 8px 12px;
    background: rgb(248, 249, 250);
    position: relative;
    border-bottom: 1px solid rgb(235, 235, 235);
  }

  &Title {
    position: relative;
    margin-left: 16px;

    &::before {
      position: absolute;
      content: '';
      display: block;
      width: 3px;
      height: 16px;
      background: #1677ff;
      left: -12px;
      top: 50%;
      transform: translate(0, -50%);
    }
  }

  &Body {
    min-height: 50px;
    overflow: hidden;
  }
}
